const router = [                                                 // роутер :)
  // роутер :)

  //empty
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk ></linda-app>',
  },
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback></linda-payment-callback>'
  },

  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/success',
    template: '<linda-payment-callback></linda-payment-callback>',
    params:{
      status: 'success',
      withdrawal: true
    }
  },

  {
    name: 'app.root.withdraw-paymentcb-fail',
    url: '/withdraw-payment/fail',
    template: '<linda-payment-callback></linda-payment-callback>',
    params:{
      status: 'fail',
      withdrawal: true
    }
  },
  // {
  //   name: 'app.root.withdraw-paymentcb-success',
  //   url: '/withdraw-payment/success',
  //   template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
  //   params:{
  //     status: 'success'
  //   }
  // },
  //
  // {
  //   name: 'app.root.withdraw-paymentcb-fail',
  //   url: '/withdraw-payment/fail',
  //   template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
  //   params:{
  //     status: 'fail'
  //   }
  // },

  {
    name: 'app.root.deposit-page',
    url: '/deposit-page',
    template: '<sweden-initial-limit-page></sweden-initial-limit-page>',
    private: true,
  },
  // {
  //     name: 'app.root.time-page',
  //     url: '/time-page',
  //     template: '<sweden-initial-time-limit-page></sweden-initial-time-limit-page>',
  //     private: true
  // },

  // {
  //   name: 'app.root.land',
  //   url: '/landing_page1',
  //   template: '<linda-view-layer-six></linda-view-layer-six>',
  // },

  // + HEADER, SIDEMENU, SEARCH
  {
    name: 'app.root.layer1',
    abstract: true,
    template: '<linda-view-layer-one></linda-view-layer-one>',
  },



  // ---- GAME PAGE ----
  {
    name: 'app.root.game',
    url: '/game/:name',
    template: '<linda-game-page></linda-game-page>',
  },
  {
    name: 'app.root.real',
    url: '/game/:name/play',
    template: '<linda-game-page-mobile></linda-game-page-mobile>',
    private: true,
    params: {
      mode: 'real'
    }
  },
  {
    name: 'app.root.demo',
    url: '/game/:name/demo',
    template: '<linda-game-page-mobile></linda-game-page-mobile>',
    params: {
      mode: 'demo'
    }
  },


  {
    name: 'app.root.layer1.registration-bank',
    url: '/fast-deposit',
    template: '<linda-zimpler-paynplay-page scroll-to-top=""></linda-zimpler-paynplay-page>',
    params: {
      mode: 'bank',
    },
  },

  // + FOOTER
  {
    name: 'app.root.layer1.layer2',
    abstract: true,
    template: '<linda-view-layer-two></linda-view-layer-two>',
  },
  {
    name: 'app.root.layer1.layer2.not-found',
    url: '/404',
    template: '<not-found ></not-found>',
  },
  //  PRROMOS
  {
    name: 'app.root.layer1.layer2.promo',
    abstract: true,
    template: '<linda-view-layer-eight></linda-view-layer-eight>',
  },
  {
    name: 'app.root.layer1.layer2.promo.list',
    url: '/promo',
    template: '<batman-promo-list2 scroll-to-top></batman-promo-list2>',
  },
  {
    name: 'app.root.layer1.layer2.promo.item',
    url: '/promo/:name',
    template: '<linda-promo-page scroll-to-top></linda-promo-page>',
  },
  /*{
        name: 'app.root.layer1.layer2.vip',
        url: '/vip-service',
        template: '<linda-vip></linda-vip>',
        params: {
            page: 'vip'
        }
    },*/

  {
    name: 'app.root.layer1.layer2.christmas-page',
    url: '/christmas',
    template: '<linda-christmas-page></linda-christmas-page>',
  },

  {
    name: 'app.root.layer1.layer2.rules',
    url: '/rules',
    template:
    '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
    },
  },
  {
    name: 'app.root.layer1.layer2.privacy-policy',
    url: '/privacy-policy',
    template:
    '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
    },
  },
  {
    name: 'app.root.layer1.layer2.cookie-policy',
    url: '/cookies-policy',
    template: '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
    },
  },
  {
    name: 'app.root.layer1.layer2.about',
    url: '/about',
    template:
    '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
    },
  },
  {
    name: 'app.root.layer1.layer2.responsible-gaming',
    url: '/responsible-gaming',
    template:
    '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
    },
  },
  {
    name: 'app.root.layer1.layer2.faq',
    url: '/faq',
    template:
    '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
    },
  },
  {
    name: 'app.root.layer1.layer2.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap></linda-sitemap>',
  },

  // + SLIDER
  {
    name: 'app.root.layer1.layer2.layer3',
    abstract: true,
    template: '<linda-view-layer-three></linda-view-layer-three>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.home',
    url: '/',
    template: '<linda-homepage></linda-homepage>',
  },

  // + profile-cashbox menu and contact-bar
  {
    name: 'app.root.layer1.layer2.layer4',
    abstract: true,
    template: '<linda-view-layer-four></linda-view-layer-four>',
  },
  {
    name: 'app.root.layer1.layer2.layer4.profile',
    abstract: true,
    template: '<linda-profile-page></linda-profile-page>',
    private: true,
  },
  {
    name: 'app.root.layer1.layer2.layer4.profile.staff',
    url: '/account/staff',
    template: '<linda-profile-staff></linda-profile-staff>',
  },
  {
    name: 'app.root.layer1.layer2.layer4.profile.settings',
    url: '/account/details',
    template: '<linda-profile-form></linda-profile-form>',
  },
  {
    name: 'app.root.layer1.layer2.layer4.profile.verification',
    url: '/account/verification',
    template: '<dvs-page scroll-to-top></dvs-page>',
  },
  {
    name: 'app.root.layer1.layer2.layer4.profile.unsubscribe',
    url: '/account/subscriptions',
    template: '<linda-profile-unsubscribe></linda-profile-unsubscribe>',
  },

  {
    name: 'app.root.layer1.layer2.layer4.cashbox',
    abstract: true,
    template: '<linda-cashbox-page></linda-cashbox-page>',
    private: true,
  },
  {
    name: 'app.root.layer1.layer2.layer4.cashbox.deposit',
    url: '/account/deposit',
    template: '<linda-cashbox-deposit></linda-cashbox-deposit>',
    params: {
      tab: 'deposit',
    }
  },
  {
    name: 'app.root.layer1.layer2.layer4.cashbox.withdraw',
    url: '/account/withdraw',
    template: '<linda-cashbox-withdraw></linda-cashbox-withdraw>',
    params: {
      tab: 'withdraw',
    }
  },

  {
    name: 'app.root.layer1.layer2.layer4.balance-history',
    url: '/account/balance-history',
    template: '<linda-cashbox-history></linda-cashbox-history>',
    private: true,
  },

  {
    name: 'app.root.layer1.layer2.layer4.selfcontrol',
    url: '/account/responsible-gaming',
    template: '<sweden-profile-limits scroll-to-top=""></sweden-profile-limits>',
    params: {
      name: 'responsible-gaming-logged',
      private: true,
    },
  },

  //- + PROVIDERS LIST
  {
    name: 'app.root.layer1.layer2.layer3.layer5',
    abstract: true,
    template: '<linda-view-layer-five></linda-view-layer-five>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.layer5.catalog',
    abstract: true,
    template: '<ui-view></ui-view>',
    params: {
      page: 'catalog',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.layer5.catalog.games',
    url: '/games/:name',
    template: '<linda-game-hall scroll-to-top=""></linda-game-hall>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.layer5.catalog.providers',
    url: '/casino-providers/:name',
    template: '<linda-game-hall-providers></linda-game-hall-providers>',
    params: {
      pageName: 'providers',
      pageType: '',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.layer5.catalog.favourites',
    url: '/favourites',
    template: '<batman-game-box-favourites1></batman-game-box-favourites1>',
    params: {
      name: 'favourites',
    },
  },
  // ---- LAST PLAYED PAGE ----
  {
    name: 'app.root.layer1.layer2.layer3.layer5.catalog.continue',
    url: '/continue-playing',
    template: '<batman-recently-played-list2 to-top-on-state-change></batman-recently-played-list2>',
    params: {
      name: 'continue-playing',
    },
  },
  {
    name: 'app.root.missedData',
    url: '/missed-data',
    //private: true,
    template: '<linda-missed-data-form></linda-missed-data-form>',
  },
  {
    name: 'app.root.layer1.layer2.application',
    url: '/application',
    template: '<linda-view-layer-seven></linda-view-layer-seven>',
  },
];



export {router};
